import React from "react";
import aboutImg from "../../assets/img/images/4.png";
import shape01 from "../../assets/img/images/about_shape01.png";
import shape02 from "../../assets/img/images/about_shape02.png";

const About = () => {
  const downloadPDF = () => {
    window.open("https://drive.google.com/uc?export=download&id=1gZV8zcztcZWCZ3ZADeIO6IBBLlErMjhs", '_blank');
  };

  return (
    <section id="mission" className="about-area-two">
      <div className="container custom-container-four">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className="about-img-two text-center wow fadeInLeft"
              data-wow-delay=".2s"
            >
              <img src={aboutImg} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title mb-15">
                <span className="sub-title">Our mission</span>
                <h3 style={{color:'white'}}>
                To empower startups, creators, and communities in the Web3 space, fostering a new era of decentralized possibilities.
                </h3>
              </div>

              <a
                href={"https://drive.google.com/file/d/1gZV8zcztcZWCZ3ZADeIO6IBBLlErMjhs/view?usp=sharing"} 
                // download
                download target="_blank" rel="noopener noreferrer"
                className="btn btn-two"
                onClick={downloadPDF} 
              >
                Read Whitepaper
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
