import React from "react";
import { Link } from "react-router-dom";
import docImg from "../../assets/img/images/document_img.png";

const WhitePaper = () => {
  return (
    <section className="document-area pt-60">
      <div className="container">
        <div className="row justify-content-center">
        <div className="col-lg-8">
        <h1 style={{textAlign:'center'}}>Investor Information</h1>
        <p style={{textAlign:'center'}}>Stay informed about Webypto's investment opportunities. Here's what you need to know:</p>
        <p>
        <br/>
        <h2>- Private Sale:</h2>
        Our Private Sale round is divided in 3 rounds, offering exclusive investment opportunities. Join early to become a part of Webypto's success story.</p>
        <br/>
        <h2>- Private Sale Round 2:</h2>
        Keep an eye out for our upcoming Private and Pre-Sale rounds, providing a chance to secure your stake in Webypto.<p>
        <br/>

        <h2>- Private Sale Round 3:</h2>
        Will be announcing soon.
        </p>
        
   
        <h5 className="neon">How to Participate:</h5>
        <div className="row justify-content-center">
        <div className="col-lg-10">
       <p> To participate in our investment rounds, follow these simple steps:
            <ul>
                <li>
                1. Visit our investment portal at [Link to Investment Portal].
                </li>
                <li>
                2. Create an account or log in if you already have one.
                </li>
                <li>
                3. Connect with your wallet.
                </li>
                <li>
                3. Navigate to the ongoing or upcoming investment round.
                </li>
                <li>
                4. Follow the instructions to complete the investment process.
                </li>
            </ul>
       </p>    </div></div>
            <p>
            Seize this opportunity to be a part of Webypto's journey. Your support fuels our growth and innovation.</p>
           
        <br/>
        <br/>
        <h3>Use of Funds</h3>
        <h5 className="neon">Empowering Webypto's Growth</h5>
        <p>The funds generated through the token sale will be strategically allocated to propel 
        the Webypto ecosystem to new heights. 
        We are committed to transparency and efficiency in fund utilization.</p><br/>

        <h5 className="neon">Allocation Breakdown:</h5>
        <div className="row justify-content-center">
            <div className="col-lg-11">
        <p><h6>Product Development:</h6>
        <div className="row justify-content-center">
        <div className="col-lg-11">
            <ul className="list">
                <li>
                Enhancing and optimizing the Webypto platform.
                </li>
                <li>
                Iterative improvements based on user feedback.
                </li>
                <li>
                Technical upgrades and innovations.
                </li>
            </ul></div>
        </div>
        </p>

        <p><h6>Marketing and Community Engagement:</h6>
        <div className="row justify-content-center">
        <div className="col-lg-11">
        <ul className="list">
                <li>
                Global marketing campaigns to increase awareness.
                </li>
                <li>
                Community-building initiatives and events.
                </li>
                <li>
                Collaborations with influencers and industry partners.
                </li>
            </ul></div></div>
        </p>

        <p><h6>Legal and Compliance:</h6>
        <div className="row justify-content-center">
        <div className="col-lg-11">
            <ul className="list">
                <li>
                Legal counsel engagement for ongoing compliance.
                </li>
                <li>
                Regulatory adherence and necessary certifications.
                </li>
            </ul></div></div>
        </p>

        <p><h6>Research and Innovation:</h6>
        <div className="row justify-content-center">
        <div className="col-lg-11">
        <ul className="list">
                <li>
                Investing in research for continuous innovation.
                </li>
                <li>
                Exploring new technologies and trends.
                </li>
            </ul></div></div>
        </p>

        <p><h6>Operational Expenses:</h6>
        <div className="row justify-content-center">
        <div className="col-lg-11">
            <ul className="list">
                <li>
                Day-to-day operational costs to ensure smooth functioning.
                </li>
                <li>
                Employee salaries and team development.
                </li>
            </ul></div></div>
        </p>

        <p><h6>Reserve Fund:</h6>
        <div className="row justify-content-center">
        <div className="col-lg-11">
            <ul className="list">
                <li>
                Establishing a reserve fund for unforeseen circumstances.
                </li>
                <li>
                Ensuring long-term sustainability and stability.
                </li>
            </ul></div></div>
        </p>
        </div>
        </div>
    <br/>
    <h5 className="neon">Oversight and Reporting:</h5>
    <p>
    A dedicated committee within Webypto will oversee the allocation and utilization of funds. Regular reports on fund usage will be shared with the community to maintain transparency and trust.<br/>
    By participating in the Webypto token sale, contributors become integral partners in our journey, supporting the growth and evolution of a pioneering Web3 collaboration platform.

    </p>


        </div>
        </div>
      </div>    
    </section>
  );
};

export default WhitePaper;
