import React from "react";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import LayoutOne from "../../layouts/LayoutOne";

const Tokenomics = () => {
  return (
    <LayoutOne pageTitle={"Tokenomics"} item={"Tokenomics"}>
    <WhitePaper />
    </LayoutOne>
  );
};

export default Tokenomics;
