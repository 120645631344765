import React from "react";
import TeamOne from "../../components/Team/TeamOne";
import LayoutOne from "../../layouts/LayoutOne";

const Team = () => {
  return (
    <LayoutOne pageTitle={"Team"} item={"TeamOne"}>
    <TeamOne />
    </LayoutOne>
  );
};

export default Team;
