import React from "react";
import img01 from "../../assets/img/team/2.png";
import img02 from "../../assets/img/team/1.png";
import img03 from "../../assets/img/team/3.png";

import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "Faheem Shaikh",
      designation: "Founder & CEO",
      link:"https://www.linkedin.com/in/faheem-shaikh-71265088/",
    },
    {
      src: img02,
      name: "Nileema Jadhav       ",
      designation: "Chief Operating Officer",
      link: "https://www.linkedin.com/in/nileema-jadhav-7b2114128/",
    },
    {
      src: img03,
      name: "Pritpal Singh",
      designation: "Chief Technology Officer",
      link: "https://www.linkedin.com/in/pmsinghin/",
    },
  ];

  return (
    <section className="team-area pt-10">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">OUr team</span>
              <h2 className="title">
                The Leadership <br /> <span>Team</span>
              </h2>
              <h6><br/>Our success is driven by a dedicated team of professionals, each contributing their unique expertise to propel Webypto forward. Meet the leaders guiding our journey:</h6>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} />
            </div>
          ))}
        </div>
        <div className="details">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h2>Faheem Shaikh</h2>
            <p>
            With a wealth of experience spanning 6 years in digital marketing, 4 years in startup advisory and fundraising, and 3 years in the blockchain and crypto space, Faheem Shaikh is the visionary force behind Webypto. His responsibilities encompass the ideation of the product, strategic marketing, collaborations with partners and investors, as well as overseeing the execution of tasks within the core team. Faheem's multifaceted expertise ensures that Webypto thrives in the dynamic landscape of digital innovation.
            </p>
          </div>
          <div className="col-lg-8">
            <h2>Nileema Jadhav </h2>
            <p>
            Nileema Jadhav brings 7 years of management experience, coupled with 5 years in lead generation and team handling, and 2 years in blockchain and crypto. As the Chief Operating Officer, Nileema has led the marketing team, spearheading collaborations with influencers, researching potential partners, finalizing marketing campaigns, and shouldering the responsibility of managing Webypto's social media presence. Her versatile background contributes to the seamless operation of the organization.
            </p>
          </div>
          <div className="col-lg-8">
            <h2>Pritpal Singh </h2>
            <p>
            Pritpal Singh, the Chief Technology Officer, is a seasoned professional with a rich background as the CEO of Saffron Web Services for over 9 years and 8 months. He is a distinguished expert in web development, software, and marketing, having also served in the UK for more than 3 years. Pritpal is entrusted with crucial responsibilities at Webypto, including Wireframing, leading the developer team, bug testing and fixing, and implementing strategic email marketing initiatives for future growth and collaborations. His technical prowess is instrumental in shaping Webypto's technological landscape.
            </p>
          </div>
          <h6 className="col-lg-8"><br/>Our team embodies diversity, fostering a collaborative environment to achieve Webypto's mission. 
          Interested in joining us? Contact us to explore exciting opportunities.</h6>
          
        </div>
      </div>
      </div>
    </section>
  );
};

export default TeamOne;
