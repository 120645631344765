import React from "react";
import { Link } from "react-router-dom";
import docImg from "../../assets/img/images/document_img.png";

const WhitePaper = () => {
  return (
    <section className="document-area pt-60">
      <div className="container">
        <div className="row justify-content-center">
        <div className="col-lg-8">
        <h1 style={{textAlign:'center'}}>Tokenomics</h1>
        <p>Navigating the decentralized landscape, Webypto introduces a 
        comprehensive tokenomics model to underpin the functionality and governance of our platform. 
        The Webypto Token (WYPTO) is the cornerstone of our ecosystem, providing additional utility to 
        projects and community members engaging with the Webypto launchpad and campaigns.</p>
        <p>
          <h4>4.1 Webypto Token (WYPTO)</h4>
The WYPTO token is meticulously crafted to serve as the governance and utility token of the Webypto ecosystem. With a total supply of 5 billion tokens, WYPTO empowers users and projects within our platform, facilitating seamless collaboration and community growth.

        </p>
        <h4>4.2 Token Distribution</h4>
Ensuring a fair and transparent distribution, the WYPTO tokens are allocated across key segments:
<p>
<br/><h6>Initial Distribution:</h6>
A portion of the tokens will be allocated for the initial distribution, ensuring widespread access to the Webypto ecosystem.
</p>
<p>
<h6>
Community Rewards:</h6>
To incentivize and reward active community participation, a significant portion of the tokens is earmarked for community rewards. Users engaging in tasks, campaigns, and contributing to the growth of the community will be eligible for these rewards.
</p>
<p>
<h6>
Development Fund:</h6>
Dedicated to ongoing development and improvement of the Webypto ecosystem, a portion of the tokens is allocated to the development fund.
</p>
<p>
<h6>
Team and Advisors:</h6>
Recognizing the contributions of our dedicated team and advisors, a portion of the tokens is allocated to ensure ongoing commitment and alignment of interests.
</p>
<p>
<h4>
4.3 Token Utility</h4>
The WYPTO token serves as a versatile utility within the Webypto ecosystem, providing users with access to exclusive features and benefits:
</p>
<p>
<h6>
Governance:</h6>
WYPTO holders have the power to influence the direction of the Webypto platform through governance proposals, offering a decentralized decision-making process.
</p>
<p>
<h6>
Platform Access:</h6>
Certain platform features and services will be accessible exclusively through the use of WYPTO tokens, creating a demand for the token within the ecosystem.
</p>
<p>
<h6>
Task Rewards:</h6>
Users participating in Webypto tasks, campaigns, and collaborations will receive task rewards in WYPTO tokens, fostering active engagement and contribution.
</p>
<p>
<h4>
4.4 Governance Mechanism</h4>
Webypto embraces a decentralized governance mechanism, empowering WYPTO token holders to actively participate in shaping the future of the platform:
</p>
<p>
<h6>
Voting Rights:</h6>
WYPTO holders have the right to vote on critical platform decisions, including proposed upgrades, feature implementations, and adjustments to tokenomics.
</p>
<p>
<h6>
Proposal Submissions:</h6>
Any WYPTO holder can submit proposals for platform enhancements or changes, fostering community-driven innovation and improvement.
</p>        
<p>
<h6>
Transparent Decision-Making:</h6>
The governance mechanism is designed to be transparent, ensuring that decisions are made collectively, reflecting the diverse interests of the Webypto community.
As Webypto evolves, the WYPTO token will play a pivotal role in shaping the platform's future, fostering a decentralized and community-driven ecosystem that aligns with the values and goals of Web3 collaboration.
</p>

<p>Total Supply:  5 Billion<br/>
Private Sale Round 1:  1 BNB = 1000000<br/>
Private Sale Round 1:  1 BNB = 700000<br/>
Private Sale Round 1:  1 BNB = 500000<br/>
Burning Tokens: 2.5% on every round include presale and ICOs/IDOs.</p>
        </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
