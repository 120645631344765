import React from "react";
import Faq from "../../components/Faq/Faq";
import LayoutOne from "../../layouts/LayoutOne";

const About = () => {
  return (
    <LayoutOne pageTitle={"Faq"} item={"Faq"}>
    <Faq />
    </LayoutOne>
  );
};

export default About;
