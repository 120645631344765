import React from "react";


const FaqTwo = () => {

  return (
    <section id="faqtwo" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">FAQs</span>
              <h2 className="title">
              Explore Answers to Common Queries: 
              </h2>
              <p style={{textAlign:'center'}}><br/>Have questions about Webypto and our token? Dive into our FAQs to find comprehensive answers. 
              We believe in transparency and are here to address any queries you may have.</p>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-12" style={{textAlign:'center'}}><a href="faqs" className="btn">Explore</a></div>
            </div>
            
          </div>
        </div>

      </div>
    </section>
  );
};

export default FaqTwo;
