import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { scrollToTop } from "../../lib/helpers";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="BigTech Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                    At Webypto, we are driven by a vision to create 
                    a thriving ecosystem where innovation flourishes. 
                    Founded by a team of experts passionate about Web3,
                    we bring you a launchpad that goes beyond boundaries.
                    </p>
                    <ul className="footer-social">
                      <li>
                        <a href="https://t.me/+ggtGvV4C8E0xMmU9">
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/webypto">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://instagram.com/webypto?igshid=NGVhN2U2NjQ0Yg==">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://whatsapp.com/channel/0029VaGTAcE8qIzpsXfqka2h">
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/webypto/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                      <li>
                        <Link to="https://drive.google.com/file/d/1PynybzhwhQGgZAtluwpSG-toDHvDUk5B/view?usp=sharing">Roadmap & Tokenomics</Link>
                      </li>
                      <li>
                        <Link to="https://drive.google.com/file/d/1hNkTRBbiF7RE-ST6tn7xZgni1Sm0IrzN/view?usp=drive_link">LitePaper</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Community</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="https://t.me/+ggtGvV4C8E0xMmU9">Telegram</Link>
                      </li>
                      <li>
                        <Link to="https://twitter.com/webypto">Twitter</Link>
                      </li>
                      <li>
                        <Link to="https://whatsapp.com/channel/0029VaGTAcE8qIzpsXfqka2h">Whatsapp</Link>
                      </li>
                      <li>
                        <Link to="https://instagram.com/webypto?igshid=NGVhN2U2NjQ0Yg==">Instagram</Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/company/webypto/">LinkedIn</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Subscribe Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                    Whether you have inquiries, suggestions, or simply want to connect, we're here for you.                    </p>
                    <form action="#">
                      <input
                        type="email"
                        placeholder="support@webypto.com"
                        required
                      />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023. All Rights Reserved Webypto</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="#">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
