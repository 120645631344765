import React from "react";
import LayoutOne from "../../layouts/LayoutOne";
import Roadmap from "../../components/Roadmap/Roadmap";

const RoadMap = () => {
  return (
    <LayoutOne pageTitle={"RoadMap"} item={"RoadMap"}>
    <Roadmap />
    </LayoutOne>
  );
};

export default RoadMap;
