import React, { useState } from "react";
import RoadmapTwoItem from "./RoadmapTwoItem";

const RoadmapTwo = () => {
  const [activeYear, setActiveYear] = useState(2023);

  const roadmap_items = {
    2023: [
      {
        roadmapTitle: "October 2023",
        title: "Project Ideation Begins",
        info: <>Webypto's journey begins with the conceptualization of a groundbreaking project idea.</>,
      },
      {
        roadmapTitle: "November 2023",
        title: "Startup Foundation",
        info: <>Finalized startup name, domain, and token creation process.</>,
      },
      {
        roadmapTitle: "December 2023",
        title: "Token Deployment and Team Formation",
        info: <>Token deployed on the mainnet.<br/>
        Commencement of Private Sale round.<br/>
        Team expansion: Finalized and added 4 members.
        </>,
      },
    ],
    2024: [
      {
        roadmapTitle: "January 2024        ",
        title: "Team Expansion and Market Entry",
        info: <>
        Further team expansion: Added 4 more members.<br/>
        Creation of social media accounts on major platforms.<br/>
        Initiation of product development.<br/>
        Launch of Airdrop #1 to attract a user base.<br/>
        Phase 1 of social media marketing campaigns.<br/>
        Commencement of the Private Sale International round</>,
      },
      {
        roadmapTitle: "February 2024        ",
        title: "Strategic Collaborations        ",
        info: <>Collaboration with influencers and influencer agencies for a mass marketing campaign.
        </>,
      },
      {
        roadmapTitle: "March 2024        ",
        title: "Product Development Milestones        ",
        info: <>Completion of the product's prototype.</>,
      },
      {
        roadmapTitle: "April 2024        ",
        title: "Product Testing Phase        ",
        info: <>Product Phase 1 ready for testing.
        </>,
      },
    
    ],
    2025: [
      {
        roadmapTitle: "January-March 2025        ",
        title: "Mobile App Launch        ",
        info: <>Launch of Android and iOS versions on Play Store and App Store.<br/>
        Application for listing the token on major centralized exchanges.
        </>,
      },
      {
        roadmapTitle: "June 2025",
        title: "Diversification into Centralized Exchange        ",
        info: <>Initiation of work on a centralized crypto exchange.
        </>,
      },
      {
        roadmapTitle: "December 2025    ",
        title: "Landmark Achievement  ",
        info: <>Launch of the centralized exchange, marking a significant milestone in Webypto's journey.

        </>,
      },
      // ... other items for 2025
    ],
  };

  const roadmap_items2={
    2023:[],

    2024:[
      {
        roadmapTitle: "May 2024    ",
        title: "Refinement and Bug Fixes        ",
        info: <>Testing of the product with subsequent bug fixes. </>,
      },
      {
        roadmapTitle: "June 2024        ",
        title: "Beta Version Launch        ",
        info: <>Launch of the Beta version of the product to gather user feedback.
        </>,
      },
      {
        roadmapTitle: "August 2024     ",
        title: "Product Enhancement        ",
        info: <>Completion of Product Phase 2, ready for testing.
        </>,
      },
      {
        roadmapTitle: "October 2024        ",
        title: "Web Version Launch        ",
        info: <>Launch of the complete web version of the product.        </>,
      },
    ],
    2025:[],
  }
  const roadmap_items3={
    2023:[],

    2024:[
       {
        roadmapTitle: "December 2024        ",
        title: "Mobile Expansion        ",
        info: <>
        Launch of the Android Beta version of the product.<br/>
        Token listing on major decentralized exchanges.
        </>,
      },
    ],
    2025:[],
  }
  const handleYearChange = (newYear) => {
    setActiveYear(newYear);
  };

  return (
    <section id="roadmap" className="roadmap-area-two pt-30 pb-10">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-65">
              <span className="sub-title">roadmap</span>
              <h2 className="title">
                Webypto Strategy and <br />
                Project Plan - {activeYear}
              </h2>
            <div className="col-lg-12">
            <p>Embark on a visual journey through our roadmap, showcasing 
            key milestones and future developments. Witness the evolution of 
            Webypto and the impactful strides we plan to take in the Web3 space.</p>
            <a href="/roadmap" className="btn">Explore Roadmap</a>
          </div>
            </div>
          </div>
        </div>
        <div className="row moveyear">
     
            <button className='btn year' onClick={() => handleYearChange(activeYear - 1)} disabled={activeYear === 2023}>
              &lt; Previous Year
            </button>
          {/* </div>
          <div className="col-lg-3"> */}
            <button className='btn year' onClick={() => handleYearChange(activeYear + 1)} disabled={activeYear === 2025}>
              Next Year &gt;
            </button>
      
        </div>
        <br/><br/>
        <div className="row">
          <div className="col-lg-12">
            <div className="roadmap-wrap-two">
              {roadmap_items[activeYear].map((item, index) => (
                <RoadmapTwoItem key={index} item={item} />
              ))}
            </div>
            {activeYear !==2023 && activeYear !==2025 &&(
            <div className="roadmap-wrap-two">
              {roadmap_items2[activeYear].map((item, index) => (
                <RoadmapTwoItem key={index} item={item} />
              ))}
            </div>
            )}
            {activeYear !==2023 && activeYear !==2025 &&(
            <div className="roadmap-wrap-two">
              {roadmap_items3[activeYear].map((item, index) => (
                <RoadmapTwoItem key={index} item={item} />
              ))}
            </div>
            )}
          </div>
        </div>
        <br/><br/>
        <div className="row moveyear">
     
     <button className='btn year' onClick={() => handleYearChange(activeYear - 1)} disabled={activeYear === 2023}>
       &lt; Previous Year
     </button>
   {/* </div>
   <div className="col-lg-3"> */}
     <button className='btn year' onClick={() => handleYearChange(activeYear + 1)} disabled={activeYear === 2025}>
       Next Year &gt;
     </button>

 </div>
      
      </div>
    </section>
  );
};

export default RoadmapTwo;
