import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import { useLocation } from "react-router-dom";
import About from "./pages/About/About";
import Faq from "./pages/Faq/Faq";
import Contact from "./pages/Contact/Contact";
import Roadmap from "./pages/RoadMap/RoadMap";
import Team from "./pages/Team/Team";
import Tokenomics from "./pages/Tokenomics/Tokenomics";
import Investor from "./pages/Investor/Investor";

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="faqs" element={<Faq />}/>
        <Route path="contact" element={<Contact />}/>
        <Route path="roadmap" element={<Roadmap />}/>
        <Route path="team" element={<Team />}/>
        <Route path="tokenomics" element={<Tokenomics />}/>
        <Route path="investor" element={<Investor />}/>
      </Routes>
    </>
  );
}

export default App;
