import React from "react";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutOne from "../../layouts/LayoutOne";

const About = () => {
  return (
    <LayoutOne pageTitle={"About"} item={"WhoWeare"}>
    <WhoWeAre />
    </LayoutOne>
  );
};

export default About;
