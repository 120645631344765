import React from "react";
import img01 from "../../assets/img/images/1.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                About<span> Webypto </span>- Unleashing the Power of Web3
                </h2>
              </div>
              <p>
              Welcome to Webypto, where boundless collaboration converges with 
              limitless innovation in the Web3 space. Webypto stands at the forefront of a decentralized 
              revolution, reshaping the way communities engage, create, and thrive.
              </p>
              <Link to="https://sales.webypto.com/" target="_blank" className="btn">
                Purchase Tokens
              </Link>
            </div>
          </div>
          <div className="row justify-content-center">
          <div className="about-content col-lg-10">
          <p> <br/>
          <span className="span">Our Mission:</span> <br/>At Webypto, our mission is to empower individuals and projects across the Web3 spectrum, fostering a dynamic ecosystem where collaboration knows no bounds. We believe in unlocking the full potential of decentralized technologies to create a future where every participant is a contributor, and every idea has the power to shape the world.
<br/><br/>
<span className="span">Visionaries Behind Webypto:</span><br/> Meet the visionary minds driving the Webypto revolution. Our founding team comprises individuals with diverse backgrounds, united by a shared passion for decentralized collaboration and innovation. Their collective expertise spans blockchain technology, decentralized finance, and community building, creating a powerhouse dedicated to redefining collaboration in the Web3 era.
<br/><br/>
<span className="span">Our Journey, Your Milestones:</span><br/> As we embark on this transformative journey, Webypto has already etched its mark with milestones that underscore our commitment to excellence. From the deployment of the WYPTO token on the mainnet to the commencement of private sale rounds, every step reflects our dedication to creating a collaborative landscape that transcends boundaries.
<br/><br/>
<span className="span">Into the Web3 Future:</span> <br/>Webypto is not just a platform; it's a testament to the limitless possibilities of decentralized collaboration. As we look ahead, our vision extends beyond the horizon, aiming to create an ecosystem where every idea, every collaboration, and every individual contributes to a future we collectively shape.

<br/><br/>
Join us on this extraordinary journey into the heart of Web3, where Webypto is not just a platform – it's a revolution in collaboration, waiting to unfold.
<br/><br/>
Explore Webypto and be part of the Web3 evolution.
<br/><br/><button className='btn'>Get Started</button>
</p>
</div></div>

        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
