import React, {useState} from "react";
import shape01 from "../../assets/img/images/faq_shape01.png";
import shape02 from "../../assets/img/images/faq_shape02.png";
import shape03 from "../../assets/img/images/faq_shape03.png";
import FaqItem from "./FaqItem";

const Faq = () => {
  const [activeSection, setActiveSection] = useState(1);

  const section1Questions = [
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: true,
      btnClass: "",
      contentClass: "show",
      title: "1. What is Webypto?",
      details: `Webypto is a comprehensive Web3 collaboration platform, 
      providing tools for community engagement, token launches, and more.`,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "2. How does Webypto engage communities?",
      details: `Webypto leverages gamified tasks, automation, and scalable solutions 
      to engage communities, fostering collaboration and growth.`,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "3. What unique features does Webypto offer?",
      details: `Webypto integrates a Launchpad, community engagement system,
      and credential data network, creating a holistic ecosystem for projects.
      `,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "4. How can I get involved with Webypto?",
      details: `Join our community on social media, subscribe to newsletters, 
      and explore opportunities for participation in campaigns and collaborations.
      `,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "5. Is Webypto only for Web3 companies?      ",
      details: `Originally focused on Web3, Webypto has expanded 
      its scope to also benefit Web2 companies, enabling broader community engagement.
      `,
    },
    {
      id: "headingSix",
      controls: "collapseSix",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "6. What is the Webypto ID?",
      details: `Webypto ID is a universal identity feature, 
      allowing users to create a digital identity tied to their web3 activities.
      `,
    },
    {
      id: "headingSeven",
      controls: "collapseSeven",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "7. Can I launch campaigns on Webypto?",
      details: `Yes, Webypto offers a Launchpad feature where startups 
      can initiate campaigns, token sales, and engage with the community.`,
    },
    {
      id: "headingEight",
      controls: "collapseEtight",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "8. How secure is Webypto?",
      details: `Webypto prioritizes security with smart contract audits and 
      compliance measures, ensuring a safe environment for users and projects.
      `,
    },
];

  const section2Questions = [
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: true,
      btnClass: "",
      contentClass: "show",
      title: "1. When is the next token sale round?",
      details: `Information about upcoming token sale rounds 
      is announced through official channels, including social media and the Webypto website.
      `,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "2. How can I participate in the private sale round?",
      details: `To participate in the private sale, follow the 
      instructions provided on the Webypto website during the specified timeframe.
      `,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "3. What benefits do private sale participants receive?",
      details: `Private sale participants often enjoy exclusive bonuses, 
      early access, and other incentives, enhancing their engagement with Webypto.`,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "4. Is there a minimum investment requirement for token sales?",
      details: `Minimum investment requirements, if any, are outlined in the 
      terms and conditions of each token sale round.
      `,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "5. How are funds from token sales utilized by Webypto?",
      details: `Transparent details about the use of funds are provided, 
      ensuring investors are informed about the allocation and purpose of raised funds.

      `,
    },
    {
      id: "headingSix",
      controls: "collapseSix",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "6. Are there any restrictions on participating in token sales?",
      details: `Token sale participation may be subject to certain restrictions, 
      outlined in the terms and conditions to ensure legal compliance.
      `,
    },
    {
      id: "headingSeven",
      controls: "collapseSeven",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "7. Can I contribute in cryptocurrencies other than WYPTO?",
      details: `Details about accepted cryptocurrencies during token sales are
      specified in the instructions provided for each round.

      `,
    },
    {
      id: "headingEight",
      controls: "collapseEtight",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "8. How often does Webypto conduct token sales?",
      details: `Token sales are conducted periodically, with announcements
       made through official channels to keep the community informed.

      `,
    },
  ];
  const section3Questions = [
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: true,
      btnClass: "",
      contentClass: "show",
      title: "1. What is the WYPTO token?      ",
      details: `WYPTO is the governance token of Webypto,
       providing utility and participation benefits within the Webypto ecosystem.
      `,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "2. How is the WYPTO token used within Webypto?      ",
      details: `WYPTO holders can participate in governance decisions, 
      voting on key matters that shape the direction of Webypto.

      `,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "3. How can I acquire WYPTO tokens?      ",
      details: `WYPTO tokens can be obtained through participation in token
       sales rounds or by engaging in community activities as per campaign incentives.
      `,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "4. 4. What is the total supply of WYPTO tokens?      ",
      details: `The total supply of WYPTO is 5 billion tokens, with 
      transparent distribution and allocation mechanisms.

      `,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "5. Can I earn rewards with WYPTO tokens?      ",
      details: `Yes, WYPTO holders may receive rewards through participation in 
      specific community activities and campaigns.
      `,
    },
    {
      id: "headingSix",
      controls: "collapseSix",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "6. Are there any benefits for long-term WYPTO holders?      ",
      details: `Long-term WYPTO holders enjoy enhanced governance participation 
      and may receive exclusive benefits in future ecosystem developments.
      `,
    },
    {
      id: "headingSeven",
      controls: "collapseSeven",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "7. How can I stay updated on WYPTO token developments?      ",
      details: `Regular updates on WYPTO token developments are provided through 
      official channels, including social media and newsletters.
      `,
    },
    {
      id: "headingEight",
      controls: "collapseEtight",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "8. Will WYPTO tokens be tradable on major exchanges in the future?      ",
      details: `We are exploring opportunities to list WYPTO on major exchanges. 
      Stay tuned for updates on potential exchange listings.`,
    },
  ];
  const faq_items = activeSection === 1
  ? section1Questions
  : activeSection === 2
    ? section2Questions
    : section3Questions;

  // const faq_items = [
    
  // ];
  const handleSectionChange = (sectionNumber) => {
    setActiveSection(sectionNumber);
  };

  return (
    <section id="faq" className="faq-area">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title section-title-two text-center mb-60">
              <h2 className="title">Frequently asked questions</h2>
              <p style={{color:'black'}}>Explore Answers to Common Queries</p>
            </div>

            <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
            <div className="section-switch-buttons col-lg-12">
          <button
            className={`btn ${activeSection === 1 ? "active" : ""}`}
            onClick={() => handleSectionChange(1)}
          >
            Webypto FAQs
          </button>
          <button
            className={`btn ${activeSection === 1 ? "active" : ""}`}
            onClick={() => handleSectionChange(2)}
          >
           Token Sale Rounds FAQs
          </button>
          <button
            className={`btn ${activeSection === 1 ? "active" : ""}`}
            onClick={() => handleSectionChange(3)}
          >
            WYPTO Token FAQs
          </button>
        </div>
        <br/>
              <div className="accordion" id="accordionExample">
                {faq_items.map((x, index) => (
                  <FaqItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
