import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactTwo from "../../components/Contact/ContactTwo";
import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
import TeamTwo from "../../components/Team/TeamTwo";
import TopPartners from "../../components/TopPartners/TopPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAreTwo from "../../components/WhoWeAre/WhoWeAreTwo";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import About from "../../components/About/About";
import Faq from "../../components/Faq/Faq";
import FaqTwo from "../../components/Faq/FaqTwo";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />
        <WhoWeAreTwo />
        <WhyChooseUs />
        <About />
        {/* <Sales /> */}
        <TeamTwo />

        <div className="area-bg">
          <RoadmapTwo />
          <FaqTwo/>
        </div>

     
        <ContactTwo />
      </main>
    </LayoutOne>
  );
};

export default Home;
