import React from "react";
import LayoutOne from "../../layouts/LayoutOne";
import Investor from "../../components/WhitePaper/InverstorInformation";

const RoadMap = () => {
  return (
    <LayoutOne pageTitle={"RoadMap"} item={"RoadMap"}>
    <Investor />
    </LayoutOne>
  );
};

export default RoadMap;
