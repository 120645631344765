import React from "react";
import img01 from "../../assets/img/images/1.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                About<span> Webypto </span>- Unleashing the Power of Web3
                </h2>
              </div>
              <p>
              At Webypto, we are driven by a vision to create a thriving 
              ecosystem where innovation flourishes. Founded by a team of 
              experts passionate about Web3, we bring you a launchpad that 
              goes beyond boundaries. Explore the future of collaboration with Webypto!

              </p>
              <Link to="https://webypto.co/register" target="_blank" className="btn">
                Register Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
