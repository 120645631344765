import React from "react";
import img01 from "../../assets/img/team/2.png";
import img02 from "../../assets/img/team/1.png";
import img03 from "../../assets/img/team/3.png";
import TeamTwoItem from "./TeamTwoItem";

const TeamTwo = () => {
  const team_members = [
    {
      src: img01,
      name: "Faheem Shaikh",
      designation: "Founder & CEO",
      link:"https://www.linkedin.com/in/faheem-shaikh-71265088/",

    },
    {
      src: img02,
      name: "Nileema Jadhav",
      designation: "Chief Operating Officer",
      link: "https://www.linkedin.com/in/nileema-jadhav-7b2114128/",

    },
    {
      src: img03,
      name: "Pritpal Singh",
      designation: "Chief Technology Officer",
      link: "https://www.linkedin.com/in/pmsinghin/",

    },
  ];

  return (
    <section className="team-area-two team-bg">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr team</span>
              <h2 className="title">
              Meet the Visionaries Driving Webypto:  <br />
                <span>Team</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((x, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6">
              <TeamTwoItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamTwo;
