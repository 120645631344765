import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pb-10">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="section-title text-center mb-60">
              <h2 className="title">
              Our Journey - <span> Milestones and Future Visions</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">

          <div className="col-lg-10 milestone">
                <h2>Milestones</h2>
            <div className="month">
                <h6>
                October 2023
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Project Ideation Begins
                </p>
                <p>
                <ul className="list">
                  <li>
                  Webypto's journey begins with the conceptualization of a groundbreaking project idea.
                  </li>
                </ul>
                </p>
              </div>
            </div>
          </div>


          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                November 2023
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Startup Foundation
                </p>
                <p>
                <ul className="list">
                  <li>
                  Finalized startup name, domain, and token creation process.
                  </li>
                </ul>
                </p>
              </div>
            </div>
          </div>    

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                December 2023
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Token Deployment and Team Formation
                </p>
                <p>
                <ul className="list">
                  <li>
                  Token deployed on the mainnet.
                  </li>
                  <li>
                  Commencement of Private Sale round.
                  </li>
                  <li>
                  Team expansion: Finalized and added 4 member.
                  </li>
                </ul>
                </p>
              </div>
            </div>
          </div>    

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                January 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Team Expansion and Market Entry
                </p>
                <p>
                <ul className="list">
                  <li>
                  Further team expansion: Added 4 more members.
                  </li>
                  <li>
                  Creation of social media accounts on major platforms.
                  </li>
                  <li>
                  Initiation of product development.
                  </li>
                  <li>
                  Launch of Airdrop #1 to attract a user base.
                  </li>
                  <li>
                  Phase 1 of social media marketing campaigns.
                  </li>
                  <li>
                  Commencement of the Private Sale International round.
                  </li>
                </ul>
                </p>
              </div>
            </div>
          </div> 

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                February 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Strategic Collaborations
                </p>
                <p>
                <ul className="list">
                  <li>
                  Collaboration with influencers and influencer agencies for a mass marketing campaign.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                March 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Product Development Milestones
                </p>
                <p>
                <ul className="list">
                  <li>
                  Completion of the product's prototype.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                April 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Product Testing Phase
                </p>
                <p>
                <ul className="list">
                  <li>
                  Product Phase 1 ready for testing.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                May 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Refinement and Bug Fixes
                </p>
                <p>
                <ul className="list">
                  <li>
                  Testing of the product with subsequent bug fixes.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                June 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Beta Version Launch
                </p>
                <p>
                <ul className="list">
                  <li>
                  Launch of the Beta version of the product to gather user feedback.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                August 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Product Enhancement
                </p>
                <p>
                <ul className="list">
                  <li>
                  Completion of Product Phase 2, ready for testing.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                October 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Web Version Launch
                </p>
                <p>
                <ul className="list">
                  <li>
                  Launch of the complete web version of the product.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                December 2024
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Mobile Expansion
                </p>
                <p>
                <ul className="list">
                  <li>
                  Launch of the Android Beta version of the product.
                  </li>
                  <li>
                  Token listing on major decentralized exchanges.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                January-March 2025
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Mobile App Launch
                </p>
                <p>
                <ul className="list">
                  <li>
                  Launch of Android and iOS versions on Play Store and App Store.
                  </li>
                  <li>
                  Application for listing the token on major centralized exchanges.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                June 2025
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Diversification into Centralized Exchange
                </p>
                <p>
                <ul className="list">
                  <li>
                  Initiation of work on a centralized crypto exchange.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 milestone">
            <div className="month">
                <h6>
                December 2025
                </h6>
              <div className="col-lg-8 milestones">
                <p>
                Landmark Achievement
                </p>
                <p>
                <ul className="list">
                  <li>
                  Launch of the centralized exchange, marking a significant milestone in Webypto's journey.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-10 future">
          <br/>
          <h2>Future Developments</h2>
            <div className="goals">
              <div className="col-lg-8 milestones">
              <br/>
                <h4>
                Continuous Growth and Innovation
                </h4>
                <p>
                <ul className="list">
                  <li>
                  Ongoing product enhancements based on user feedback.
                  </li>
                  <li>
                  Community engagement programs to involve users in shaping Webypto's future.
                  </li>
                  <li>
                  Global expansion to explore new markets and regions.
                  </li>
                  <li>
                  Strategic partnerships with key industry players for enhanced positioning.
                  </li>
                  <li>
                  Research and development to stay at the forefront of technological advancements.
                  </li>
                  <li>
                  User education initiatives to promote awareness and understanding.
                  </li>
                  <li>
                  Ecosystem growth through the introduction of new services and features.
                  </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>


      </div>
      </div>
    </section>
  );
};

export default Roadmap;
