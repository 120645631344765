import React from "react";
import ContactOne from "../../components/Contact/ContactOne";
import LayoutOne from "../../layouts/LayoutOne";

const Contact = () => {
  return (
    <LayoutOne pageTitle={"Contact"} item={"CONTACT"}>
    <ContactOne />
    </LayoutOne>
  );
};

export default Contact;
